import EventEmitter from 'events';

import { is_user_registered as iur } from './primal';
const spec_json = require('../doc/billing/spec.json');

export class blBase extends EventEmitter {
  init_retry_count = 10

  spec_json = spec_json

  update_bl = () => {
    this.emit("update");
  }

  init = user => {
    if (this._initialized && !user) return;

    let u = user || (buildsite && buildsite.user && buildsite.user());

    if (u && u.open) {
      this._user = u;
      u.open(this.update_bl);
      this.setMaxListeners(500);
      this.on("update", () => { console.log("permissions updated"); });
      this._initialized = true;
    } else if (--this.init_retry_count){
      // buildsite.user not loaded, try again later
      console.log("init retry");
      window.setTimeout(this.init, 100);
    }
  }

  close() {
    this._user && this._user.removeListener && this._user.removeListener("change", this.update_bl);
  }

  is_user_registered(user) {
    let u = (this._user || {}).data || user;
    return iur(u);
  }

  plan_status(plan_id) {
    return (spec_json && spec_json.core_plans[plan_id])
      ? 'success'
      : undefined;
  }

  plan_family(plan_id) {
    if (spec_json.core_plans[plan_id]) return 'core';
    if (spec_json.products[plan_id]) return 'product';
    if (spec_json.addon_plans[plan_id]) return 'addon';
    return undefined;
  }

  plan_name(plan_id) {
    if (spec_json && spec_json.core_plans[plan_id]) {
      return spec_json.core_plans[plan_id].name;
    } else if (spec_json.products[plan_id]) {
      return spec_json.products[plan_id].name;
    }
    return undefined;
  }

  fee(plan_id) {
    if (spec_json && spec_json.core_plans[plan_id]) {
      return parseInt(spec_json.core_plans[plan_id].price, 10)/100;
    } else if (spec_json.products[plan_id]) {
      return parseInt(spec_json.products[plan_id].price, 10)/100;
    }
    return undefined;
  }

  // takes in the plan name of a button on the pricing page
  // returns one of:
  // - "upgrade"
  // - "current"
  // - "no_longer"
  // - "disabled"
  // - "signup"
  target_plan(plan_id) {
    if (!this._user) return 'signup';
    var uc = this._user.data && this._user.data.company;
    if (uc && uc.mode) {
      if (uc.mode == "trial") return plan_id == "trial" ? "current" : "upgrade";
      if (plan_id == "trial") return "no_longer";
    }

    var curr_plan_id = uc && uc.plan;
    if (curr_plan_id && curr_plan_id == "alpha_trial") curr_plan_id = "trial";
    if (this._user.data && uc && !curr_plan_id) {
      // employee
      return plan_id == "trial" ? "disabled" : "upgrade";
    } else if (!curr_plan_id) return 'signup';
    if (plan_id == curr_plan_id) return 'current';

    if (spec_json && spec_json.core_plans[curr_plan_id] && spec_json.core_plans[plan_id]) {
      var core_plan_progression_order = {};
      spec_json.core_plan_progression.forEach(function(el,i){
        core_plan_progression_order[el] = i;
      });
      if (typeof core_plan_progression_order[plan_id] == "undefined") return 'disabled'; // not paid plan
      if (typeof core_plan_progression_order[curr_plan_id] == "undefined") return 'upgrade';
      return core_plan_progression_order[plan_id] > core_plan_progression_order[curr_plan_id] ? "upgrade" : "downgrade";
    } else {
      // not core plan
      return 'disabled';
    }
    return 'signup';
  }

  is_paidsub() {
    if (!this._user) return false;
    var cm = this._user.data && this._user.data.company && this._user.data.company.mode;
    if (!cm) return false;
    return (cm == "paidsub");
  }

  have_cc() {
    if (!this._user) return false;
    var b = this._user.data && this._user.data.company && this._user.data.company.profile;
    b = b && b.billing;
    if (!b) return false;
    return (b.card_id && b.exp_month && b.exp_year && b.last4);
  }

  is_companyadmin() {
    if (!this._user) return true;
    var b = this._user.data && this._user.data.is_company_admin;
    return b;
  }

  basic_features_available() {
    if (!this._user) return true;
    var b = this._user.data && this._user.data.company;
    b = b && b.can;
    if (!b) return true;
    if (typeof b.basic_features == "undefined") return false;
    return b.basic_features;
  }

  mode() {
    if (!this._user) return undefined;
    var uc = this._user.data && this._user.data.company;
    if (uc && uc.mode) { return uc.mode; }
    return undefined;
  }

  user_plan_name() {
    return this.plan_name(this.user_plan_id()) || "";
  }

  user_plan_id() {
    if (!this._user) return "";
    var plan_id = this._user.data && this._user.data.company;
    plan_id = plan_id && plan_id.plan;
    return plan_id || "";
  }

  billing_cycle_ends() {
    if (!this._user) return 0;
    var b = this._user.data && this._user.data.company;
    b = b && b.profile && b.profile.billing_cycle_ends;
    return b || 0;
  }

  trial_expires() {
    if (!this._user) return 0;
    var b = this._user.data && this._user.data.company;
    b = b && b.profile && b.profile.trial_expires;
    return b || 0;
  }

  added_employees(q) {
    if (!this._user) return 0;
    var b = this._user.data && this._user.data.company;
    if (!b) return 0;
    var curr_plan_id = b.plan;
    b = b.can;
    var m = curr_plan_id && spec_json.core_plans[curr_plan_id];
    m = m && m.can && m.can.have_users_max;
    if (m && b.have_users_max) {
      if (q) return q + m;
      if (b.have_users_max > m) return b.have_users_max - m;
    }
    return 0;
  }

  have_employees() {
    if (!this._user) return 0;
    var b = this._user.data && this._user.data.company && this._user.data.company.users;
    if (b && b.length) {
      return _.filter(b, (e) => { return e.status == 'live' }).length;
    } else return 0;
  }
}
