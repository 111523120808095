import React from 'react';

export default class Locker extends React.Component {
  setLock = (bool) => {
    //NOTE: To run this method in parent's scope add ref there, i.e. <Locker ref={(ref) => this.locker = ref}>...</Locker>

    if (bool === true || bool === undefined) {
      $(this.lockerRef).addClass(this.props.children.props.className + " _locked");
    } else {
      $(this.lockerRef).removeClass(this.props.children.props.className + " _locked");
    }
  }

  render() {
    return (
      <div className="locker_btn"
           ref={(ref) => this.lockerRef = ref}
      >
        {this.props.children}
      </div>
    );
  }
}
