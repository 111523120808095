import React from 'react';
import FixedPositionedBlock from './fixedPositionedBlock';


export default class InfoTooltip extends FixedPositionedBlock {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }

  render() {
    var ttClass = this.props.ttColor ? "info_icon_small _" + this.props.ttColor : "info_icon_small";
    var ttChar = this.props.ttChar ? this.props.ttChar : "i";

    return (
      <div ref={this.parentRef} onMouseEnter={this.handleOver} onMouseLeave={this.handleOut} className={"sm-tooltip-wrap fixed " + (this.props.ttWrapClass || "")}>
        <span className={ttClass}>{ttChar}</span>
        <div ref={this.infoRef} className={"sm-tooltip" + (this.state.visible ? " visible" : "")}>{this.props.children || this.props.ttText}</div>
      </div>
    );
  }
}
