import React from 'react';

function RadioButton(props) {
  let thisProps = {
    type: "radio",
    name: (props.radioName && props.radioName.length) ? props.radioName : "",
    "data-path": props["data-path"],
    "data-delay": "1",
    "data-value": props["data-value"],
    onChange: (props.radioChanged !== undefined) ? props.radioChanged : null,
    value: (props.radioValue !== undefined) ? props.radioValue : ""
  };
  let classes = ["radio_button"];

  if (props.radioChecked !== undefined) {
    thisProps.checked = props.radioChecked == true;
  } else if (props.radioDefaultChecked !== undefined) {
    thisProps.defaultChecked = props.radioDefaultChecked == true;
  } else {
    thisProps.checked = false;
  }

  if (props.radioDisabled) {
    thisProps.disabled = props.radioDisabled;
    classes.push("disabled");
  }

  if (props.addClasses) classes.push(props.addClasses);

  return (
    <label className={classes.join(" ")}>
      <input {...thisProps} tabIndex="0" />
      <div className="radio_button-body">{props.children}</div>
    </label>
  );
}

//!!! defaultChecked prop can not be used with checkboxChecked prop at the same time
function CheckBox(props) {
  let disabled = props.checkboxDisabled ? props.checkboxDisabled : false;
  let checkboxChecked = disabled ? false : props.checkboxChecked;
  let classes = props.customLabel ? [] : ["checkbox_button"];

  if (disabled) classes.push("disabled");
  if (props.addClasses) classes.push(props.addClasses);

  let body = (
      <>
        <input type="checkbox"
               tabIndex="0"
               id={props.id || null}
               name={props.checkboxName || null}
               value={props.checkboxValue || undefined}
               checked={checkboxChecked || (typeof props.defaultChecked !== 'undefined' ? undefined : false)}
               data-path={props["data-path"] || null}
               onChange={props.checkboxChanged}
               disabled={disabled || props.disabled}
               defaultChecked={props.defaultChecked}
        />
        <div className="checkbox_button-body">{props.children}</div>
      </>
  );
  if (props.customLabel) return (
      <div className="checkbox_button">
        <label htmlFor={props.id} className={classes.join(" ")} data-tooltip={props["data-title"] || null}/>
        {body}
      </div>
  );

  return (
    <label className={classes.join(" ")} data-tooltip={props["data-title"] || null}>
      {body}
    </label>
  );
}

function PseudoCheckBox(props) {
  let checked = !!props.checked;

  return (
    <div className={`pseudo_checkbox${checked?' _checked':''}`}>
      <div className="__input" />
      <div className="__body">{props.children}</div>
    </div>
  )
}

export { RadioButton, CheckBox, PseudoCheckBox };
