import React from 'react';
import { EventEmitter } from 'events';
import { documentsWarningMsg } from './dialog/DownloadWithOptsDlg';

import './sass/spinner-overlay.scss';

const download_beam_notice = (dtype, hasWarningPdf)  => {
  let addMsg = documentsWarningMsg(hasWarningPdf);
  return (
    <span>
      <b>Your download is under construction. Large {dtype} may take some time.</b>
      <br/><br/>
      <span>
        Downloads should render perfectly in Adobe Acrobat, Bluebeam 2019 or&nbsp;
        later, and most other PDF readers. If you are using an older version of&nbsp;
        Bluebeam, you may need to upgrade or view the pdf in Acrobat.
      </span>
      {addMsg && <><br/><br/><span>{addMsg}</span></>}
    </span>
  );
}


class Spinner extends React.Component {
  displayName = 'Spinner';

  state = {spin: false}

  constructor(props) {
    super(props);

    if (!window._spinner) this.spinner();

    console.log("Spinner mount");
    this.spinner().on('on', this.show_spinner);
    this.spinner().on('off', this.hide_spinner);
  }

  spinner = () => {
    if (window._spinner) return window._spinner;
    window._spinner = new EventEmitter();
    return window._spinner;
  }

  componentWillUnmount() {
    console.log("Spinner unmount");
    this.spinner().removeListener('on', this.show_spinner);
    this.spinner().removeListener('off', this.hide_spinner);
  }

  show_spinner = opts => {
    this.setState(_.extend({spin: true, template: "", className: "", text: ""}, opts || {}));
  }

  hide_spinner = () => {
    this.setState({spin: false, className: "", text: "", hasWarningPdf: undefined});
  }

  template_override = (template) => {
    switch (template) {
      case "package":
        return download_beam_notice("packages", this.state.hasWarningPdf);
      case "project":
        return download_beam_notice("projects");
      case "rfi":
        let addMsg = documentsWarningMsg(this.state.hasWarningPdf);
        return (
          <span>
            <b>Your download is under constuction.</b>
            {addMsg && <><br/><br/><span>{addMsg}</span></>}
          </span>
        );
      case "project_export":
        return (
          <span>
            <b>Your download is under construction. Large project may take some time.</b>
          </span>
        )
      default:
        return null;
    }
  };

  render() {
    let state = this.state, template = state.template, text_block = state.text;

    if (template && this.template_override(template)) text_block = this.template_override(template);

    return (
      <div className={classNames("spinner-overlay", state.className, {"enabled": state.spin})}>
        <div className="loader"></div>
        <div className="text">{text_block}</div>
      </div>
    );
  }
}

module.exports = Spinner;
