// https://builds.atlassian.net/browse/SUB-182
// https://builds.atlassian.net/browse/SUB-185
// https://builds.atlassian.net/browse/SUB-315
// sets up window.buildsite.logPageView and window.buildsite.logEvent

import ga from './ga-tracking';
import ac from './ac-tracking';

if (!window.buildsite) window.buildsite = {};
if (!window.buildsite.config) window.buildsite.config = {};

const b = window.buildsite,
  c = window.buildsite.config;

b.logPageView = function(page) {
  // console.log("buildsite.logPageView()");
  if (c.ga_tracking_id) {
    ga.logPageView(page);
  }
  if (c.ac_tracking_id) {
    ac.logPageView(page);
  }
  if (window.ldfdr && 'function' === typeof window.ldfdr.track) window.ldfdr.track();
};

b.logEvent = function (e) {
  // console.log("buildsite.logEvent()");
  if (c.ga_tracking_id) {
    ga.logEvent(e);
  }
  if (c.bing_uet_id && e && e.category !== 'api') {
    window.uetq = window.uetq || []; window.uetq.push({ec:e.category, ea:e.action, el:e.label});
  }
};

if (c.hj_tracking_id) {
  window.onload = () => (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:c.hj_tracking_id,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

b.logGAds = function (event) {
  if (!c.g_ads_tracking_id || !c.g_ads_tracking_conversion) return;

  let sendTo = c.g_ads_tracking_conversion[event];

  if (sendTo) ga.logGAds('conversion', {
    'send_to': sendTo
  });

}

//console.log( "buildsite-tracking.js is set up" );

// eof
