import React from 'react';

export default class DownloadFailDlg extends React.Component {
  closeModal = e => {
    if (e && 'function' == typeof e.preventDefault) e.preventDefault();
    window.modal('hide');
  }

  render() {
    var msg = this.props.msg || "We're sorry but it looks like we've had a problem creating your PDF. Please try again.";
    return (
      <div className="modal-dialog">
        <form className="modal-content" onSubmit={this.closeModal}>
          <div className="modal-header">
            <a href="#" className="close-icon" onClick={this.closeModal}></a>
            <h4>Error</h4>
          </div>
          <div className="modal-body">
            <div className="modal-body-transparent_wrapper _info_message">
              <p>
                {msg}
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <div className="modal-footer-right_row">
              <input type="submit" value="OK" className="sub_btn _blue" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
