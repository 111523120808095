/**
BuildSite Client-Side JavaScript API v0.3

https://docs.google.com/document/d/1GjA65mA2DczacNFkLaBa-498Cbn68YWasjmfAkUOA9E/edit#

@module buildsite.api

*/


/*********  api.project class  *********/

import { BaseObject } from '../base/object';

export class SubscriptionObject extends BaseObject {
    constructor(api, id, data, req_opts) {
        super(api, "subscription", id, data, req_opts);
    }
}


/*********  api.subscription() method  *********/

export default function api_subscription(id, data, req_opts) {
    let api = this;
    let o = new SubscriptionObject(api, id, data, req_opts);

    /*
    // default limit is ~10
    o.setMaxListeners(500);
    */

    return o;
}
