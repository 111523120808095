import {
  not_implemented,
  walkThrough,
  clean_file,
  ac_params_factory,
  download_factory,
  fileIsViewable,
  pr,
  user_not_reged,
  getEpoch,
  isRoObject
} from '../../ui-components/common';

import {
  checkReturnSubmitRules,
  unassignedOrphanFlags,
  unassignedOrphanNote,
  feedbackOptions,
  outsideSubmittedDocuments,
  getFeedbackLabel
} from '../../business/common';

export const pkg_find_row_by_row_id = function(pkg, row_id) {
  var row_path = -1;
  $.each(pkg.data.package_data.sections, function(j, section) {
    $.each(section.rows, function(i, row) {
        if (row && row.id && row.id == row_id) {
          row_path = "package_data/sections#"+section.id+"/rows#"+row.id;
          return false;
        }
    });
  });

  return row_path;
}

// https://builds.atlassian.net/browse/SUB-477 package highlighting
export function shouldHighlight(tm) {
  let flags = this.props.flags;
  if (!flags.highlightOn) return false;
  if (!tm || !flags.flowLastAck) return false;
  return (tm > flags.flowLastAck);
}

// https://builds.atlassian.net/browse/SUB-477 package highlighting
export function shouldHighlightField(obj,sfield, selsewhere_field) {
  let flags = this.props.flags,
      field = sfield,
      elsewhere_field = selsewhere_field;

  if (!flags.highlightOn) return false;
  // all new SUB-477 3.1 -- No more highlighted
  // https://builds.atlassian.net/browse/SUB-477?focusedCommentId=111707&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-111707
  if (!flags.flowLastAck) return false;
  if (!obj[field]) {
    if (!elsewhere_field) return false;
    field = selsewhere_field;
    elsewhere_field = undefined;
    if (!obj[field]) return false;
  }
  // local modification time
  var mtime = obj[field + '_mtime'];
  // modification time from elsewhere
  var __mtime = obj['__' + field + '_mtime'];

  // if a field is locally-modified, do not highlight it
  if (mtime && __mtime && (mtime > __mtime)) return false;
  // feedback use flow_from_above_feedback for example
  if (elsewhere_field && mtime) {
    let __mtime = obj[`__${elsewhere_field}_mtime`];
    if (__mtime && mtime > __mtime) return false;
  }
  return (__mtime > flags.flowLastAck);
}

export function getDefaultRowData() {
  return {
    subsection: '',
    product_specified: '',
    manufacturer: '',
    product_submitted: '',
    items: [],
    tags: {},
    notes: ''
  };
}

export function is_add_doc_btn_top(preg) {
  if (preg && preg.mode && preg.mode == "review") return true;
  return false;
}

export function feedbackMenu(pkg, row, key = 'base') {
  if (!pkg || !row) return [];

  const fbOptions = feedbackOptions(key);
  const flow_level = pkg.registry.flow_level;

  let feedBackMenuItems = _.reduce(fbOptions, (arr, rowFeedback) => {
    let showRowFeedback = true;

    if (rowFeedback.only_on_created_below_rows) {
      if (typeof row.flow_origin_level === 'undefined' || row.flow_origin_level >= flow_level) {
        showRowFeedback = false;
      }
    }

    if (flow_level > 0 && !rowFeedback.can_use_level_above_0
      || flow_level === 0 && !rowFeedback.can_use_level_0
      || flow_level < 0 && !rowFeedback.can_use_level_below_0) {
      showRowFeedback = false;
    }

    if (!showRowFeedback) return arr;

    rowFeedback.ui.forEach(uiItem => {
      arr.push({
        value: rowFeedback.code,
        item_name: uiItem.item_name,
        with_notes: uiItem.with_notes
      });
    })
    return arr;
  }, []);

  return feedBackMenuItems;
}

export function get_fmi_key_by_values(fb_menu, fb_value, fb_notes, schema) {
  if (!fb_menu || !fb_value) return undefined;
  if (_.isUndefined(fb_notes)) fb_notes = null;
  let fmi_original = undefined;
  for(let fmi_i in fb_menu) {
    let fmi = fb_menu[fmi_i];
    if (fmi.value == fb_value) {
      if (fmi.with_notes ? fb_notes != null : fb_notes === null) {
        if (fmi_original) {
          console.error("get_fmi_key_by_values: more than 1", fb_menu, fb_value, fb_notes, fmi_original, fmi);
          return;
        }
        fmi_original = fmi;
      }
    }
  }
  if (!fmi_original) return undefined;
  //base schema has equal codes with/without notes so we differ them by "_with_notes" appendix
  return fmi_original.value + ((schema === 'base' && fmi_original.with_notes) ? "_with_notes" : "");
}

export function get_fmi_by_key(fb_menu, fb_key, schema) {
  if (!fb_menu || !fb_key) return undefined;
  let fb_menu_map = {};
  for(let fmi_i in fb_menu) {
    let fmi = fb_menu[fmi_i];
    //base schema has equal codes with/without notes so we differ them by "_with_notes" appendix
    fb_menu_map[fmi.value+((schema === 'base' && fmi.with_notes) ? "_with_notes" : "")] = fmi;
  }

  if (!fb_menu_map[fb_key]) {
    console.error("get_fmi_by_key: not found", fb_menu, fb_key);
    return undefined;
  }
  return fb_menu_map[fb_key];
}

export function onClickPreview(e) {
    pr(e);
    buildsite.api().emit("op-fail");
}

export const successUnsubmitText = [
    `Package was successfully unsubmitted from `,
    `Package was successfully resubmitted to `
]

export const row_fields = ["id", "subsection", "product_specified", "product_submitted", "manufacturer", "notes"];

export const hasWarningPdf = pkg => {
  if (!pkg || !pkg.package_data) return;
  let sections = pkg.package_data.sections,
      hasNonPdf = false,
      hasLockedPdf = false;

  const itemChecking = item => {
    if (!hasNonPdf && item.mime_type !== "application/pdf") {
      hasNonPdf = true;
    }
    let f = item.flags || {};
    if (item.mime_type === "application/pdf" && (!item.is_annotatable || f.cpdf_broken || f.pdftk_broken)) hasLockedPdf = true;

    if (hasNonPdf && hasLockedPdf) return false;
  }

  _.forEach(sections, section => {
    if (hasNonPdf && hasLockedPdf) return false;
    _.forEach(section.rows, row => {
      if (hasNonPdf && hasLockedPdf) return false;
      _.forEach(row.items, itemChecking);
    })
  });

  //documents from reviewer
  if (_.get(pkg, 'package_data.returned_documents.items', []).length && (!hasNonPdf || !hasLockedPdf)) {
    _.forEach(pkg.package_data.returned_documents.items, itemChecking);
  }

  //documents from assignee
  if (_.get(pkg, 'package_data.documents.items', []).length && (!hasNonPdf || !hasLockedPdf)) {
    _.forEach(pkg.package_data.documents.items, itemChecking);
  }

  return {hasNonPdf, hasLockedPdf};
}

export {
  not_implemented,
  walkThrough,
  clean_file,
  ac_params_factory,
  download_factory,
  fileIsViewable,
  pr,
  user_not_reged,
  isRoObject,
  checkReturnSubmitRules,
  getEpoch,
  unassignedOrphanFlags,
  unassignedOrphanNote,
  outsideSubmittedDocuments,
  getFeedbackLabel
}
