/* eslint-disable indent */

function isDeleted(o) {
    if (!o) return false;
    return o.status && (o.status == "deleted" || o.status == "gone");
}

function is_user_registered(u) {
  let us = u && u.status;
  if (!us) return false;
  return ["ghost", "initial"].indexOf(us) == -1;
}

function capitalize(s) {
  return (typeof s !== 'string')
    ? ''
    : s.charAt(0).toUpperCase() + s.slice(1);
}

function prettyName(name, email) {
    let pName = null;

    if (name) {
        pName = name;
        if (email) {
            pName += ` (${email})`;
        }
    } else if (email) {
        pName = email;
    }

    return pName;
}

/* source: api/controllers/api/0.3/base.js */
function cleanPath(path, nojoin) {
    let cp = path.split("/").map(p => p.replace(/#.*$/g, ''));
    return nojoin ? cp : cp.join('/');
}

module.exports = {
  isDeleted,
  is_user_registered,
  capitalize,
  prettyName,
  cleanPath
};
