import Bugsnag from '@bugsnag/js';
import _ from 'lodash';

function getUser() {
  if (buildsite && typeof buildsite.user === 'function') {
    const user = buildsite.user();

    if (user && user.data) return {
      id: user.data.user_id,
      name: user.data.name,
      email: user.data.email_address,
      company: _.get(user, 'data.company.name')
    };
  }

  return {};
}

let user = getUser();

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  releaseStage: process.env.NODE_ENV,
  appVersion: process.env.APP_VERSION,
  ...(Object.keys(user).length > 0 ? {
    user: {
      id: user.id,
      name: user.name,
      email: user.email,
      company: user.company
    },
  } : {}),
  onError: function (event) {
    event.addMetadata('info', {
      env: process.env.NODE_ENV,
      revision: process.env.APP_REVISION
    });

    user = getUser();

    if (buildsite) {
      if (Object.keys(user).length > 0) {
        event.addMetadata('customer', {
          id: user.id,
          name: user.name,
          email: user.email,
          company: user.company
        });
      }
    }
  }
});
