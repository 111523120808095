// this file has API documentation in Yuidoc format:
// http://yui.github.io/yuidoc/syntax/index.html
// To generate the html view of the doc:
//
//    crunch $ grunt yuidoc
//
// or use "grunt watch" to recompile it automatically
// on every file change.
//
// Use "grunt http-server:doc" to read the docs in your browser.
//
/**
BuildSite Client-Side JavaScript API v0.3

https://docs.google.com/document/d/1GjA65mA2DczacNFkLaBa-498Cbn68YWasjmfAkUOA9E/edit#

@module buildsite.api

*/

import { ApiBase } from '../base/api';

import api_user from '../main/user';
import api_user_auth from '../main/user_auth';
import api_subscription from '../main/subscription';

class ApiPublic extends ApiBase {
    constructor(base) {
        super(base, "public");

        Object.assign(this, {
            user:              api_user,
            user_auth:         api_user_auth,
            subscription:      api_subscription
        });
    }

    /**
    Register user.

    Usage:

        api.user_register(
            {login_id:"bsmith", password:"12345", name:"Ben Smith", email:"bsmith@email.com"},
        )
        .done(function(data, status){...})
        .fail(function(xhr, status, err){...})
        ;

    @method user_register
    @for api
    */

    user_register(op_details) {
        return this.queue_req({
            op:   "register",
            type: "user",
            id:   "_",
            data: Object.assign({}, op_details, {skipErrorHandling: true})
        });
    }
}


module.exports = function() {

if (!window.buildsite) window.buildsite = {};
if (!window.buildsite.logEvent) window.buildsite.logEvent = function(){};

// global api objects
// having added new object please update code for api_stop()
let __user;

let __api;

buildsite.api = function () {
    if (!__api) {
        console.log( "initializing buildsite.api() ..." );
        __api = new ApiPublic("/api/0.3");
    }

    return __api;
};

buildsite.__object = function(oid, id, data, opts) {
    let object = this.__objects[oid];

    if ("function" != typeof __api[oid]) {
        throw new Error("No API object!");
    }

    if (object) {
        if (["initial", "open", "opening"].indexOf(object.status) > -1) {
            if (object.req_id == id) { return object; }
            if (object.status == "open") object.close(); // close old object //may be need force closed, not only "open" at lest
        }
      //console.log("__object closing", object.req_id, id, (object.req_id == id));
    }

    this.__objects[oid] = __api[oid](id, data, opts);

    return this.__objects[oid];
}

buildsite.user = function() {
    if (__user ) { return __user; } //&& __user.status != 'initial'

    __user = this.api().user("_");

    return __user;
}

// returns current session user
buildsite.currentUser = function() {
    return this.user();
}

require('../../../ui-components/mlac-errors');

};
