import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import WithUser from '../../sub-ui/js/WithUser'

// set up google analytics and other tracking
require('../../ui-components/buildsite-tracking');
// load business logic
require('../../business/public')();

require('../../mla-c/0.3/public')();

require('../scss/main.scss');

import PublicContainer from './modules/public-container';
import Modal from '../../ui-components/modal';
import Spinner from '../../ui-components/spinner';
import UnauthNotify from '../../ui-components/unauth-notify';
import PhoneCall from './modules/PhoneCall'

function App(props) {
  return (
    <WithUser>
      <Router>
        <MetaTags>
          <title>Submittal.com</title>
        </MetaTags>
        <UnauthNotify isPublic={true} />
        <Spinner />
        <PhoneCall />
        <Modal />
        <PublicContainer />
      </Router>
    </WithUser>
  );
}

ReactDOM.render(<App />, document.getElementById('react_container'));
