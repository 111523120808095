import React, {useState} from 'react';
import { img } from './common';


const url = _.get(window, 'buildsite.config.calendly_urls.schedule_a_call_url'),
  onClick = () => window.open(url);

export default function PhoneCall(props) {
  let [closed, setClosed] = useState(false);

  let hideBlock = (e) => {
    e.stopPropagation();
    setClosed(true)
  }

  if (!url) return null;

  return (
    <div className={"phoneCall" + (closed ? ' closed' : '')} onClick={onClick}>
      <img src={img('icons/PhoneCall.svg')} alt="Call us" width="24px" height="24px"/>
      <div><b className="black">Schedule a call</b> to talk to a real person</div>
      <div className="close-icon" onClick={hideBlock}>+</div>
    </div>
  );
}