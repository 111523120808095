import React from 'react';
import {pr} from './common';

export default class FixedPositionedBlock extends React.PureComponent {
  constructor(props) {
    super(props);
    this.parentRef = React.createRef();
    this.infoRef = React.createRef();
  }

  componentWillUnmount() {
    if (this.scrollListener) document.removeEventListener('wheel', this.handleScroll, true);
  }

  handleScroll = () => {
    if ((this.state || {}).visible) {
      document.removeEventListener('wheel', this.handleScroll, true);
      this.scrollListener = false;
      this.setState({visible: false});
    }
  }

  handleOver = e => {
    pr(e);
    document.addEventListener('wheel', this.handleScroll, true); //scroll event not fires in bubbling phase by default
    this.scrollListener = true;
    if (this.props.fixedPositioned && this.parentRef.current && this.infoRef.current) {
      let el = this.parentRef.current,
          elRect = el.getBoundingClientRect(),
          bodyRect = document.body.getBoundingClientRect(),
          top = elRect.bottom - bodyRect.top,
          left = elRect.left - bodyRect.left - 4;
      this.infoRef.current.style.top = top + 'px';
      this.infoRef.current.style.left = left + 'px';
      this.infoRef.current.style.position = 'fixed';
      this.infoRef.current.style.zIndex = '1000';
    }
    this.setState({visible: true});
  }

  handleOut = e => {
    pr(e);
    if (this.scrollListener) {
      document.removeEventListener('wheel', this.handleScroll, true);
      this.scrollListener = false;
    }
    this.setState({visible: false});
  }

}