import React, { Suspense } from 'react';

const SuspenseFallback = () => {
  React.useEffect(() => {
    window._spinner && window._spinner.emit("on", {className: 'suspense', text: 'Loading ...'});
    return () => {
      window._spinner && window._spinner.emit('off');
    }
  }, []);

  return null;
}

export default (props) => (
  <Suspense fallback={props.noFallback ? null : <SuspenseFallback/>}>
    {props.children}
  </Suspense>
)
