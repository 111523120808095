import React from 'react';

export default class WithUser extends React.Component{
  state = {
    gotUser: false
  }

  componentDidMount() {
    buildsite.user().open(this.updateUser).fail(this.updateUser);
  }

  componentWillUnmount() {
    buildsite.user().close(this.updateUser);
  }

  updateUser = (data) => {
    if (!this.state.gotUser) this.setState({gotUser: true});
  }

  render () {
    if (!this.state.gotUser) {
      return (
        <div className="userLoading" />
      );
    }

    return (
      <>
        {this.props.children}
      </>
    );
  }
}
