/**
BuildSite Client-Side JavaScript API v0.3

https://docs.google.com/document/d/1GjA65mA2DczacNFkLaBa-498Cbn68YWasjmfAkUOA9E/edit#

@module buildsite.api

*/

import EventEmitter from 'events';

const tree_ops = require('./object_tree_ops');
const DEBUG = 0;
const { cleanPath } = require('../../../business/primal');

/* applies to object DC value of TD referenced by K */
function apply_test_key(k, dc, td) {
    let tdt = typeof td, isArray = tdt == "object" && Array.isArray(td);

    if (tdt == "object") {
        if (isArray) {
            td.forEach(ae => {
                if (!ae.id) return;
                if (!dc[k]) dc[k] = [];
                if (!Array.isArray(dc[k])) return;
                dc[k].forEach(dce => {
                    if (dce.id == ae.id) Object.keys(ae).forEach(ko => apply_test_key(ko, dce, ae[ko]));
                });
                if (dc[k].length == 0) {
                    dc[k].push({});
                    Object.keys(ae).forEach(ko => apply_test_key(ko, dc[k][0], ae[ko]));
                }
            });
            if (!Array.isArray(dc[k])) {
                return console.error("apply_test_key not an array", k, dc, td);
            }
        } else {
            if (!dc[k]) dc[k] = {};
            Object.keys(td).forEach(ko => apply_test_key(ko, dc[k], td[ko]));
        }
    } else {
        dc[k] = td;
    }
}


function onApiReconnect() {
    this.clog('api reconnect event');
    this.emit("reconnect");
}

export class BaseObject extends EventEmitter {
    tree_ops = tree_ops;

    constructor(api, type, id, data, req_opts) {
        super();

        let o = this;

        Object.assign(o, {
            type:   type,
            req_id: id,
            data:   null,
            test_data: {},
            sk_timeout_id: {},
            disable_if_modified: false,
            status:   "initial",
            req_opts: req_opts,
            api
        });

        if (id == "new" && data) {
            o.initial_data = data;
        } else if (this.is_id_ro(id) && data) {
            o.ro_data = data;
            o.apply_test_data(data);
        } else if (data) {
            o.req_data = data;
        }

        this.op_promise = $.Deferred();
        this.op_promise.resolve();
    }

    stash = null

    clog() {
        let args = Array.from(arguments || []),
            prefix = this.whoami();

        if ((args || []).length && (args[0] || '').indexOf('%') > -1) {
           prefix = prefix + ' ' + args.shift();
        }
        console.log.apply(console, [prefix].concat(args));
    }

    get_static_id() {
        return (this.data && this.data.__id) ? this.data.__id : this.req_id;
    }

    object_id() {
        return this.data ? this.data.__id : null;
    }

    id = this.object_id

    is_id_ro(id) {
        return !!("string" == typeof id && id.match(/^ro_/));
    }

    whoami() {
        if (!this.whoami_value) {
            this.whoami_value = "[object] " + this.type + "/" + this.get_static_id();
        }
        return this.whoami_value;
    }

    get undo_available() {
        return !!(this.stash && this.stash.length);
    }

    undo_available_change() {
        let msg = this.undo_available && _.last(this.stash).msg;
        this.emit("undo_available_change", {msg});
    }

    undo() { return; }

    clear_undo() {
        if (this.undo_available) {
            this.stash = [];
            this.undo_available_change();
        }
    }

    apply_test_data(d) {
        if (d) this.original_data = d;

        let dc = JSON.parse(JSON.stringify(this.original_data || {}));

        Object.keys(this.test_data || {}).forEach(k => apply_test_key(k, dc, this.test_data[k]));

        this.data = dc;
        return dc;
    }

    cleanPath = cleanPath

    open_factory(classname, req_data_function) {
        let of = function(fn) {
            let rdf = req_data_function.bind(this);
            let o = this;
            let reqdata = rdf();
            let f = ("function" == typeof fn) ? fn : null;

            f && o.on("change", f);

            let op_prefix = "/" + reqdata.op + ": ";

            o.clog(op_prefix + "open", (o.open_promise ? "(already opening, status: " + o.status + ")" : ""));

            let dfd = $.Deferred();
            // already open(ing)
            if (o.open_promise) {
                if (o.status == "closed" || o.status == "open") {
                    let dfdp = dfd.promise()

                    if (o.status == "closed") { //???
                        o.status = "open"; //reopen
                        o.clog("re-open");
                        o.emit("open", o);
                    }

                    dfd.resolve(o.data);
                    f && dfdp.done(f);

                    return dfdp;
                }

                f && o.open_promise.done(f);
                return o.open_promise; // if there is open_promise that were FAIL, status remain initial until close()
            }

            // initial & closed status - reopen object
            o.open_promise = dfd.promise();
            f && o.open_promise.done(f);

            // set some default event handlers
            o.on("mlac.error", err => {
                o.clog("error: " + err.status + " " +  err.err);
                o.api.emit("mlac.error", err);
            });

            if (!o.ro_data) {
                o.on("open", data => {
                    o.clog('open event:', data);
                    o.pubSubscribe(o.pubGetRooms(data.data));
                });

                o.on("closing", () => {
                    o.clog('closing event');
                    o.pubUnsubscribe();
                });

                o.on("resub", data => {
                    o.clog('resub event');
                    o.pubResubscribe(data.data);
                });

                o.on("reconnect", () => {
                    o.clog('reconnect event');
                    o.pubSubscribe(o.pubGetRooms(o.data));
                });

                if ("function" != typeof o.onApiReconnect) {
                    o.onApiReconnect = onApiReconnect.bind(this);
                    o.api.on("reconnect", o.onApiReconnect);
                }
            }

            this.api.fetch(reqdata, o).done((data, status) => {
                if (!data || !data.status || !data.data) {
                    console.error("invalid or empty server response (response data); why are we in a .done()?");
                    o.emit("mlac.error", "invalid or empty server response (response data)");

                } else if (data.status == 'OK') {
                    o.clog(op_prefix + "ok");
                    o.apply_test_data(data.data);
                    o.status = "open";
                    o._when = data.now || Date.now();
                    dfd.resolve(o.data);
                    o.emit("open", data);

                } else {
                    o.status = "initial";
                    o.clog(op_prefix + "not ok\n", data.errors);
                    // hmm. data.errors[0]?? maybe pass the data.errors array there? --iku
                    dfd.reject({ message: data.errors[0] }, data);
                }
            })

            .fail((xhr, status, err) => {
                o.clog(op_prefix + "fail");
                o.status = "initial";
                o.emit("mlac.error",{ status: status, err: err });
                dfd.reject({ message: "network error", status: status, err: err });
            });

            o.status = "opening";
            return o.open_promise;
        };

        return of.bind(this);
    }

    open(...args) {
        return this.open_factory("object", () => {
            return $.extend(true, {
                op:    "get",
                type:  this.type,
                id:    this.req_id,
                data:  this.req_data,
                ifModified: false
            }, this.req_opts);
        })(...args);
    }

    close(p) {
        let o = this;

        !p && o.clog("close");

        if (typeof p == "function") {
            o.removeListener("change", p);
        }

        let cc = o.listenerCount("change");

        if (DEBUG) o.clog("listeners:",
          "open:", o.listenerCount("open"),
          "closing:", o.listenerCount("closing"),
          "resub:", o.listenerCount("resub"),
          "reconnect:", o.listenerCount("reconnect"),
          "api reconnect:", o.api.listenerCount("reconnect")
        );

        if (cc > 0) {
            if (DEBUG) o.clog("close, skipped, listeners", cc);
            return;
        }

        o.emit("closing");
        delete o.open_promise;
        o.status = "closed";
        o.data = null;
        delete o._when;
        o.stop_polling();
        o.removeAllListeners();
        if ("function" == typeof o.onApiReconnect) o.api.removeListener("reconnect", o.onApiReconnect);

        if (DEBUG) {
            o.clog("close this:", this);
            p && o.clog("close");
        }
    }

    get(fn) {
        return this.open().done(fn).done(this.close.bind(this));
    }

    // object watch polling
    start_polling() {
        /*
        this.stop_polling();
        if (this.status != "closed") {
          this.timeout_id = setTimeout(this.get_snapshot.bind(this), this.api.poll_timer*1000);
          //console.log("new timeout_id:", this.timeout_id);
        }
        */
    }

    stop_polling() {
        if (this.timeout_id) {
            //console.log("clearing timeout_id:", this.timeout_id);
            clearTimeout( this.timeout_id );
            this.timeout_id = null;
        }
    }

    delay_polling() {
        this.delay_timeout = 1000;
        this.stop_polling();
    }

    handle_done(data) {
        let o = this;

        if (!data) {
            o.clog("handle_done: data is empty");
            return;
        }

        let old_status = (o.data || {}).status || "";

        if (data.status == 'OK') {
            o.disable_if_modified = false;

            if (!data.data) // status only response
                return;

            // has the server actually sent us a (newer) version of the same object data?
            // if not, exit

            // some checks only make sense in case of individual objects:
            if (o.type != "collection") {
                // this shouldn't happen, but currently can:
                if (Array.isArray(data.data)) // collection for single object
                    return;

                if (   data.data.__type === undefined
                    || data.data.__id === undefined
                    || !data.data.__type)
                  return;

                if (   o.data
                    && o.data.__type
                    && o.data.__type != data.data.__type)
                    return;

                if (data.data.__type && o.type != data.data.__type)
                    return;

                if (o.data && o.data.__id != data.data.__id)
                    return;
            }

            //console.log('handle_done(): update object data', data.data);

            // same object - update data
            let dd = data.data, ns = dd.status || "";

            if (data.now) {
                o._when = data.now;
            }

            if (old_status == "live" && ns != "live") {
                o.emit("deleted");
            }

            o.apply_test_data(dd);
            o.emit("change", o.data);

            if (o._rooms) {
                o.emit("resub", o);
            }

            o.emit("external-change", o.data);

        } else if (data.errors) {
            o.clog(data.errors);
        }
    }

    get_snapshot_factory(classname, req_data_function) {
        let gs = function() {
            let rdf = req_data_function.bind(this);
            let o = this;
            let reqdata = rdf();

            let op_prefix = "/" + reqdata.op + ": ";

            o.clog(op_prefix + "request");

            reqdata.snapshot = true;
            //o.clog(classname+".get_snapshot, timeout_id:", o.timeout_id);

            let promise = this.api.queue_req(reqdata, o).done(data => {
                if (data) {
                    o.clog(op_prefix + "got data");
                    o.handle_done(data);
                } else {
                    o.clog(op_prefix + "not modified");
                }
            })
            .fail((xhr, status, err) => {
                o.clog(op_prefix + "fail");
                o.emit("mlac.error", { status: status, err: err });
                let f_err = err && err[0];
                if (f_err) {
                  if (f_err == "Record Not Found") o.emit("404");
                  if (typeof f_err == "object" && f_err.code) {
                    if (["access-canceled", "access-denied"].indexOf(f_err.code) != -1) o.emit("404");
                  }
                }
            })
            .always(() => {
              //o.clog(classname+".get_snapshot.always, status:", o.status);
              //if (o.status != "closed") o.start_polling();
            });

            o.api.delay_ping();

            return promise;
        };

        return gs.bind(this);
    }

    get_snapshot() {
        return this.get_snapshot_factory("object", () => {
            return $.extend(true, {
                type:   this.type,
                id:     this.get_static_id(),
                op:     "get",
                ifModified: !this.disable_if_modified,
                data:   this.req_data
            }, this.req_opts);
        })();
    }

    /*********  object.op() method  *********/

    /**
    Performs an operation on the object, to modify its data.

    @method op
    @for api.object
    @param {String} op — the operation, one of "set_key", "add_li", "rem_li", "move_li"
    @param {Object} op_details — the operation specifics
    @return promise, use with
      .done(function(data, status){...})
      .fail(function(xhr, status, err){...})

    */
    op(op_id, op_details, op_ext) {
        let o = this,
            _timeout_id = o.timeout_id,
            op_prefix = "/" + op_id + ": ";

        o.clog(op_prefix + "promise state '" + this.op_promise.state() + "'");

        return this.op_promise.then(() => {
            o.clog(op_prefix + "sending");
            this.op_promise = o.api.queue_req(
                $.extend(true, {
                    op:     op_id,
                    type:   o.type,
                    id:     o.get_static_id(),
                    data:   op_details
                }, op_ext),
                o
            )
            .done((data, status) => {
                if (this.undo_op) {
                    this.undo_op = false;
                    if (this.undo_available) {
                        this.stash.pop();
                        this.undo_available_change();
                    }
                }
                o.clog(op_prefix + "ok");
                o.handle_done(data);
            })
            .fail((xhr, status, err, fail_opts) => {
                o.clog(op_prefix + "fail", fail_opts);
                if ((!fail_opts || !fail_opts.performSkip) && status && status == "FAILHANDLED") {
                    o.emit("op-fail", { status: status, err: err });
                }
                this.clear_error();
            })
            .always(() => {
              // continue polling if it was temporarily stopped for the operation
              /*
              if (_timeout_id && o.status == "open")
                  o.start_polling();
              */
            });

            /*
            if (_timeout_id && o.status == "open")
                o.stop_polling();
            */

            return this.op_promise;
        });
    }

    clear_error() {
        this.op_promise = $.Deferred();
        this.op_promise.resolve();
    }

    /*
    //,pubDoSnapshot: pubDoSnapshot
    // close() should disable polling for object updates from the server
    */

    pubGetRooms() { return null }

    pubBaseUrl() {
        return [this.api.base, this.type, this.get_static_id()].join('/');
    }

    pubHandler(message) {
        this.clog('pubHandler():', {'this': this, message});

        const {event} = message || {};

        if (event == 'update') return this.pubDoSnapshot(message);

        this.clog("pubHandler(): event is '" + event + "'");
        this.emit('pubsub_' + event, message);
    }

    pubDoSnapshot(message) {
        let o = this;

        //if (DEBUG) o.clog('this:', this, 'message:', message);

        let when = this._when || 0;

        if ((message.omitSocket || '') == this.api.socketId) return;

        if (message.when > when || (message.when == when && (message.omitSocket || 0) == -1)) {
            this._when = message.when + (((message.omitSocket || 0) == -1) ? 1 : 0);

            this.timeout_id = setTimeout(() => {
                o.get_snapshot();
            }, this.delay_timeout);

            this.delay_timeout = 0;
        }
    }

    pubSubscribe(rooms) {
        let o = this;

        if (DEBUG) o.clog('rooms:', rooms);

        if (!rooms) return;

        let baseUrl = this.pubBaseUrl();
        o._doHandle = o.pubHandler.bind(o);

        if (DEBUG) o.clog('subscribing...');

        this.api.pubsub.post(baseUrl + '/subscribe', {rooms: rooms}, (resData, jwres) => {
            if (DEBUG) o.clog('resData:', resData);

            _.forEach(rooms, _room => {
                o.api.pubsub.on(_room, o._doHandle);
                if (DEBUG) o.clog('joined', _room);
            });

            this._rooms = rooms;
        });
    }

    pubUnsubscribe(rooms) {
        let o = this;

        let _rooms = rooms || this._rooms;

        if (!_rooms) return;

        if (DEBUG) o.clog('rooms:', _rooms);

        let baseUrl = this.pubBaseUrl();

        if (DEBUG) o.clog('unsubscribing...');

        _.forEach(_rooms, _room => {
            o.api.pubsub.off(_room, o._doHandle);
            if (DEBUG) o.clog('left', _room);
        });

        this.api.pubsub.post(baseUrl + '/unsubscribe', {rooms: _rooms}, (resData, jwres) => {
            if (DEBUG) o.clog('resData:', resData);
            this._rooms = null;
        });
    }

    pubResubscribe(data) {
        let o = this;

        let newRooms = this.pubGetRooms(data) || [],
            _rooms = this._rooms || [];

        let minus = _.difference(_rooms, newRooms),
            plus = _.difference(newRooms, _rooms);

        if (!(minus.length + plus.length)) {
            if (DEBUG) o.clog('unchanged');
            return;
        }

        if (DEBUG) {
            o.clog('rooms:', '\nformer:', _rooms, '\nnew rooms:', newRooms);
            o.clog('diff:', '\nminus:', minus, '\nplus:', plus);
        }

        let baseUrl = this.pubBaseUrl();

        if (DEBUG) o.clog('resubscribing...');

        this.api.pubsub.post(baseUrl + '/resubscribe', {frooms: _rooms, nrooms: newRooms}, (resData, jwres) => {
            if (!jwres || jwres.statusCode != 200) {
                console.error({jwres});
                return;
            }

            if (DEBUG) o.clog('resData:', resData);

            if (!resData) {
                console.error('no data:', {jwres});
                return;
            }

            let {minus, plus} = resData;

            _.forEach(minus, _room => {
                this.api.pubsub.off(_room, o._doHandle);
                if (DEBUG) o.clog('left', _room);
            });

            _.forEach(plus, _room => {
                this.api.pubsub.on(_room, o._doHandle);
                if (DEBUG) o.clog('joined', _room);
            });

            this._rooms = newRooms;
        });
    }

    delete(op_details) {
        return this.op('delete', op_details || {}).done(function(){ this.emit("deleted") }.bind(this));
    }

    undelete(op_details) {
        return this.op('undelete', op_details || {});
    }
}

// add tree ops
Object.assign(BaseObject.prototype, tree_ops);

export default function api_object(type, id, data, req_opts) {
    let api = this;
    let o = new BaseObject(api, type, id, data, req_opts);

    /*
    o.on("op-fail", function() {
        o.get_snapshot();
    });
    */

    // if the API was stopped, we stop polling too.
    // this won't be emitted if api_ping is disabled (current case)
    // so I disable this listener too. Do NOT enable unconditionally!
    // api.on("stop", function () { o.close(); return true; } );

    // XXX should we also listen to the "offline" event in the API?
    // Should we stop polling until we are back online?

    return o;
}

/*********  object events  *********/

/**

This event is triggered when a change in the record's data has been noticed,
either by the end-user via the api itself, or by somebody or something else,
via the server.

@event change
@for api.object
@param {Object} data the new object's data
*/

/**

This event is triggered when a change in the record's data has happened
without direct end-user action, or in addition to such actions. That is:
a change is external to this instance of the object.

@event external-change
@for api.object
@param {Object} data the new object's data
*/

/**
This event is triggered when the initial record's data arrives from the server.

@event open
@for api.object
@param {Object} data the object's data
*/

/**
This is tiggered when an error happens in the object or in a request to the server.

Possible values for the first argument (besides null) are "timeout", "error",
"abort", and "parsererror". When an HTTP error occurs, errorThrown receives
the textual portion of the HTTP status, such as "Not Found" or "Internal Server Error."

Both arguments are passed on from jQuery' ajax() method's error handlers:
http://api.jquery.com/jquery.ajax/

@event error
@for api.object
@param {String} Status
@param {String} errorThrown
*/
