import React, { lazy } from 'react';

import Suspense from '../../../ui-components/suspense';

const Forgot = lazy(() => import('./dialogForgot'));
const Recover = lazy(() => import('./dialogRecover'));
const EChange = lazy(() => import('./dialogEChange'));
const Login = lazy(() => import('./dialogLogin'));
const Interstitial = lazy(() => import('./interstitial'));

export { Forgot, Recover, EChange, Login, Interstitial }
export const all = { Forgot, Recover, EChange, Login, Interstitial }
export const suspense = (D) => (props) => <Suspense><D {...props}/></Suspense>
