const delay = (fn, ms) => new Promise(resolve => setTimeout(() => resolve(fn()), ms));

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const skipFnNCalls = (fn, n = 1, t = 0) => (...args) => n > t ? t++ : fn(...args);

module.exports = {
  skipFnNCalls,
  delay,
  sleep
};
