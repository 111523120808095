import React from 'react';
import { Link } from 'react-router-dom';

import { pr } from '../common';
import { CheckBox } from '../radio-checkbox';
import InfoTooltip from '../info-tooltip';
import { hasWarningPdf } from '../../supaed/js/common';

export const documentsWarningMsg = hasWarningPdf => {
  if (!hasWarningPdf) return null;
  let {hasNonPdf, hasLockedPdf} = hasWarningPdf;
  if (!hasNonPdf && !hasLockedPdf) return null;

  let msg = 'This download will produce a ZIP file.';
  if (hasNonPdf) {
    msg += ' Non-PDF documents will be included in their original format.';
  }
  if (hasLockedPdf) {
    msg += ' Encrypted or locked PDF documents will be included exactly as they have been uploaded.';
  }
  return msg;
}

export default class DownloadWithOptsDlg extends React.Component {
  static displayName = 'DownloadWithOptsDlg';

  constructor(props) {
    super(props);
    this.state = {
      include_stamp: false,
      include_cover: !!(props.package.package_data.cover && props.package.package_data.cover.enabled),
      include_provided_by: false
    }

    this.hasWarningPdf = hasWarningPdf(props.package);
  }

  closeModal = e => {
    pr(e);
    window.modal("hide");
    if ("function" == typeof this.props.onClose) this.props.onClose();
  };

  checkboxToggle = e => {
    let current = e.target.dataset.path;
    let newState = {};
    newState[current] = !this.state[current];
    this.setState(newState);
  }

  onSubmit = e => {
    let opts = {
      include_stamp: this.state.include_stamp,
      include_cover: this.state.include_cover,
      include_provided_by: this.state.include_provided_by,
      noWarningPdf: true //because the warning has been already shown to user
    };
    this.props.download_click_f(opts)(e);
    this.closeModal(e);
  }

  renderBlock = () => {
    let props = this.props,
        tt_text1 = <span>You can set up the cover sheet via the <br/>“add cover sheet” link in the package <br/>header.</span>,
        tt_text2 = <span>Account admin can set the Stamp <br/>via the {props.user.is_company_admin ? <Link to="/app/settings">Settings</Link> : "Settings"} screen.</span>,
        tt_text3 = <span>"Provided by" adds your distinctive signature,<br/> including name, company name and logo,<br/> to the Divider Pages on downloaded PDFs.</span>;

    return (
        <div className="wrapper">
          <div className="item">
            <CheckBox
                data-path={"include_provided_by"}
                checkboxValue={this.state.include_provided_by}
                checkboxChecked={this.state.include_provided_by}
                checkboxChanged={this.checkboxToggle}
                checkboxDisabled={props.provided_by_disabled}>
              Include "Provided by"
            </CheckBox>
            <InfoTooltip ttText={tt_text3} ttColor="blue" ttWrapClass="right"/>
            <div className="hint_block">
              "Provided by" is already in use on this package.
            </div>
          </div>
          <div className="item">
            <CheckBox
                data-path={"include_cover"}
                checkboxValue={this.state.include_cover}
                checkboxChecked={this.state.include_cover}
                checkboxChanged={this.checkboxToggle}
                checkboxDisabled={props.cover_disabled}>
              Include Cover Sheet
            </CheckBox>
            <div className="hint_block">
              No coversheet configured or uploaded.<InfoTooltip ttText={tt_text1} ttColor="blue" />
            </div>
          </div>
          {!(props.package.registry.flow_level > 0) && <div className="item">
            <CheckBox
                data-path={"include_stamp"}
                checkboxValue={this.state.include_stamp}
                checkboxChecked={this.state.include_stamp}
                checkboxChanged={this.checkboxToggle}
                checkboxDisabled={props.stamp_disabled}>
              {props.stamp_placeholder}
            </CheckBox>
            <div className="hint_block">
              Stamp is not configured in the account.<InfoTooltip ttText={tt_text2} ttColor="blue" />
            </div>
          </div>
          }
        </div>
    );
  }

  render() {
    return (
        <div className="modal-dialog download_opts">
          <form className="modal-content" onSubmit={this.onSubmit}>
            <div className="modal-header">
              <a href="#" className="close-icon" onClick={this.closeModal}></a>
              <h4>Download <span className="name">{this.props.package.title_rev || this.props.package.title}</span></h4>
            </div>
            <div className="modal-body _no_min_height">
              <div className="modal-body_content">
                {this.renderBlock()}
              </div>
            </div>
            <div className="modal-footer">
              {documentsWarningMsg(this.hasWarningPdf)}

              <div className="modal-footer-right_row">
                <a className="sub_btn" href="#" onClick={this.closeModal}>Cancel</a>
                <input type="submit" value="Complete Download" className="btn-noshadow-default btn-blue sub_btn _blue" autoFocus />
              </div>
            </div>
          </form>
        </div>
    );
  }
}
