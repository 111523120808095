import React, { lazy, useEffect } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Suspense from '../../../ui-components/suspense';

/* Pages */
const TermsOfUse = lazy(() => import('../../legalpages/termsofuse'));
const PrivacyPolicy = lazy(() => import('../../legalpages/privacypolicy'));
const CustomerAgreement = lazy(() => import('../../legalpages/customeragreement'));
const DataFormat = lazy(() => import('../../legalpages/dataformat'));
const AboutUs = lazy(() => import('./aboutus'));
const PricingGCs = lazy(() => import('./pricing/pricing-gcs'));
const PricingSubs = lazy(() => import('./pricing/pricing-subs'));
const Home = lazy(() => import('./home'));
const FeaturesGCs = lazy(() => import('./features-gcs'));
const FeaturesSubs = lazy(() => import('./features-subs'));
const FeaturesSuppliers = lazy(() => import('./features-suppliers'));
const SmallBusiness = lazy(() => import('./small-business'));
const Tutorials = lazy(() => import('./tutorials'));
const Contact = lazy(() => import('./contact'));
const QuickStart = lazy(() => import('./gc-quickstart'));
const Concrete = lazy(() => import('./landing/div-03-concrete'));
const Masonry = lazy(() => import('./landing/div-04-masonry'));
const BuildingFraming = lazy(() => import('./landing/div-06-building-framing'));
const RoofingInsulation = lazy(() => import('./landing/div-07-roofing-insulation'));
const InteriorSpecialty = lazy(() => import('./landing/div-09-interior-specialty'));
const Mechanical = lazy(() => import('./landing/div-23-mechanical'));
const SubmittalTemplates = lazy(() => import('./submittal-templates'));
const SignUp = lazy(() => import('./sign-up'));
const TurnerPage = lazy(() => import('./turner'));

import getQueryVariable from '../../../ui-components/js/getQueryVariable';

/* Dialogs { Forgot, Recover, EChange, About, Login } */
import { all as Dialogs, suspense } from '../../js/dialogs';


/* PublicWrapper */
function PublicWrapper(props) {
  useEffect(() => {
    let Dlg = Dialogs[props.dialog || ""];

    if (Dlg) {
      window.modal(suspense(Dlg), {routeParams: props.routeParams});
    }
  }, [])

  let MainClass = props.main;
  return (
    <>
      <Header page={props.page || ""} />
      <MainClass />
      <Footer />
    </>
  );
}

/* PublicContainer */
export default class PublicContainer extends React.Component {
  componentDidMount() {
    window.buildsite.logPageView();
    ["email_address", "redirect_url"].forEach(field => {
      let zz = getQueryVariable(field);
      if (zz) window.buildsite[field] = zz;
    });
    buildsite.api().on("refresh", this.goRefresh);
  }

  componentWillUnmount() {
    buildsite.api().removeListener("refresh", this.goRefresh);
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    window.buildsite.logPageView();
  }

  goRefresh = () => {
    console.warn("PublicContainer got refresh event");
  }

  render() {
    return (
      <Suspense>
        <Switch>
          <Redirect exact from="/" to="/home" />

          <Route path="/home"              render={() => <PublicWrapper page="home"              main={Home}  />} />
          <Route path="/login"             render={() => <PublicWrapper page="home"              main={Home}  dialog="Login" />} />
          <Route path="/interstitial"      render={() => <PublicWrapper page="home"              main={Home}  dialog="Interstitial" />} />
          <Route path="/sign-up"           render={() => <PublicWrapper page="sign-up"           main={SignUp} />} />
          <Route path="/emailchange/:id"   render={() => <PublicWrapper page="login"             main={Home} dialog="EChange" />} />
          <Route path="/recovery/:code/:type"    render={(p) => <PublicWrapper page="home"       main={Home} dialog="Recover" routeParams={p.match.params} />} />

          <Route path="/aboutus"           render={() => <PublicWrapper page="aboutus"           main={AboutUs}   />} />
          <Redirect exact from="/pricing/enterprise" to="/pricing/gcs" />
          <Redirect exact from="/pricing" to="/pricing/gcs" />
          <Route path="/pricing/gcs"               render={() => <PublicWrapper page="pricingGCs"  main={PricingGCs}   />} />
          <Route path="/pricing/subs"              render={() => <PublicWrapper page="pricingSubs"  main={PricingSubs}   />} />
          <Route path="/pricing/small-business"    render={() => <PublicWrapper page="pricingSmallBusiness"  main={SmallBusiness}   />} />

          <Redirect exact from="/features" to="/features-gcs" />
          <Route path="/features-gcs"      render={() => <PublicWrapper page="featuresGCs"      main={FeaturesGCs}  />} />
          <Route path="/features-subs"     render={() => <PublicWrapper page="featuresSubs"     main={FeaturesSubs}  />} />
          <Route path="/features-suppliers"     render={() => <PublicWrapper page="featuresSuppliers"     main={FeaturesSuppliers}  />} />
          <Route path="/features-small-business"     render={() => <PublicWrapper page="featuresSmallBusiness"     main={SmallBusiness}  />} />
          <Route path="/submittal-templates"   render={() => <PublicWrapper page="submittal-templates"  main={SubmittalTemplates} />} />
          <Route path="/tutorials"         render={() => <PublicWrapper page="tutorials"         main={Tutorials} />} />

          <Route path="/termsofuse"        render={() => <PublicWrapper page="terms"             main={TermsOfUse}        />} />
          <Route path="/privacypolicy"     render={() => <PublicWrapper page="policy"            main={PrivacyPolicy}     />} />
          <Route path="/customeragreement" render={() => <PublicWrapper page="customeragreement" main={CustomerAgreement} />} />
          <Route path="/dataformat"        render={() => <PublicWrapper page="dataformat"        main={DataFormat}        />} />
          <Route path="/contact"           render={() => <PublicWrapper page="contact"           main={Contact}           />} />
          <Redirect exact from="/sub-quick-start" to="/quick-start" />
          <Redirect exact from="/gc-quick-start" to="/quick-start" />
          <Route path="/quick-start"    render={() => <PublicWrapper page="quick-start"    main={QuickStart}        />} />
          <Route path="/intro/div-03-concrete"           render={() => <PublicWrapper page="concrete"              main={Concrete}       />} />
          <Route path="/intro/div-04-masonry"            render={() => <PublicWrapper page="masonry"               main={Masonry}        />} />
          <Route path="/intro/div-06-building-framing"   render={() => <PublicWrapper page="building-framing"      main={BuildingFraming}/>} />
          <Route path="/intro/div-07-roofing-insulation" render={() => <PublicWrapper page="roofing-insulation"    main={RoofingInsulation}/>} />
          <Route path="/intro/div-09-interior-specialty" render={() => <PublicWrapper page="interior-specialty"    main={InteriorSpecialty}/>} />
          <Route path="/intro/div-23-mechanical"         render={() => <PublicWrapper page="mechanical"            main={Mechanical}     />} />
          <Redirect exact from="/intro/misc" to="/intro/div-06-building-framing" />
          <Route path="/intro/turner"                    render={() => <PublicWrapper page="turner"                main={TurnerPage}     />} />

        </Switch>
      </Suspense>
    );
  }
}
