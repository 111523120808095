import React from 'react';
import ReactDOM from 'react-dom';

export default class DropdownPopup extends React.PureComponent {
  $parent = []
  $parentWrapper = []
  $dd = []

  componentDidMount() {
    if(this.props.overflow) {
      $(window).on("resize.dropdownPopup", () => {
        $(this.dropdown).removeClass('_open');
        $(this.dropdown).removeClass('_above');
      });
    }
  }

  componentWillUnmount() {
    if (this.$dd) {
      $(this.$dd).remove();
      this.$dd = [];
    }
  }

  //simple dropdowns
  ocPopup = (e) => {
    let $dd = this.$dd;

    if(!this.$dd.length) {
      $dd = $(ReactDOM.findDOMNode(this)).children('.dropdown-menu');

      this.$dd = $dd;
    }

    if(this.props.overflow) { //if dropdown is cut by a parent with overflow hidden
      if(!this.$parent.length) {
        this.$parent = $dd.parent();

        $dd.appendTo(document.body);
      }

      $dd.css({opacity: 0});

      let yPos = this.$parent.offset().top - 5,
          xPos = this.$parent.offset().left;

      $dd.toggleClass('_open');
      if (this.props.qMark) {
        document.querySelector('.questionMark').classList.toggle('_open');
        if (typeof this.props.onClick === 'function') this.props.onClick($dd.hasClass('_open') ? true : false);
      }
      $dd.removeClass('_above');

      let wid = $dd.outerWidth(),
          hei = $dd.outerHeight(),
          ddStyles;

      if((yPos + hei + 50) <= $('body').outerHeight() && !this.props.qMark) { // ~50px is average height of dropdown parent
        ddStyles = {
          bottom: 'auto',
          left: xPos - wid + 30,
          opacity: '',
          right: 'auto',
          top: yPos + 35
        }
      } else {
        $dd.addClass('_above');

        ddStyles = {
          bottom: 'auto',
          left: xPos - wid + 30 + (this.props.qMark ? 15 : 0), //assume qmark always opens above
          opacity: '',
          right: 'auto',
          top: yPos - hei - 5,
        }
      }

      $dd.css(ddStyles);
    } else {
      $dd.toggleClass('_open');
      $dd.removeClass('_above');

      if ($dd.hasClass('_open')) {
        if(!this.$parent.length) {
          this.$parent = $( (() => {
            function checkParent(newEl) {
              let newParent = newEl.parent();

              if(newParent.css('position') === 'relative' || newParent.is('body')) {
                return newParent;
              } else {
                checkParent(newParent);
              }
            }

            return checkParent($dd)[0];
          })() );

          this.$parentWrapper = $dd.closest('[data-ddwrapper]').length ? $dd.closest('[data-ddwrapper]') : $('body');
        }

        $(window).off('scroll.popupOpenScroll').on('scroll.popupOpenScroll', () => {

          let hasClassAbove = $dd.hasClass('_above');

          if(!hasClassAbove && !this.props.down) {
            let parentWrapperBottom = this.$parentWrapper.outerHeight() + this.$parentWrapper.offset().top,
                ddBottom = $dd.outerHeight() + $dd.offset().top,
                pageFooterHeight = $('.layout-footer').length ? $('.layout-footer').outerHeight() : 0,
                pageBottom = window.pageYOffset + window.innerHeight - pageFooterHeight;

            let futDdTop = window.pageYOffset - (this.$parent.offset().top + $dd.outerHeight(true));

            if((ddBottom > parentWrapperBottom || ddBottom > pageBottom) && futDdTop < 0) {
              $dd.addClass('_above');
            }
          } else {
            let pageTop = window.pageYOffset,
                ddTop = $dd.offset().top,
                parentBottom = this.$parent.offset().top + this.$parent.outerHeight(),
                parentWrapperBottom = this.$parentWrapper.offset().top + this.$parentWrapper.outerHeight(),
                pageBottom = window.pageYOffset + window.innerHeight;

            let futDdBottom = parentBottom + $dd.outerHeight(true);

            if (ddTop < pageTop && futDdBottom <= pageBottom) {
              $dd.removeClass('_above');
            }
          }
        }).trigger('scroll.popupOpenScroll');

      } else {
        $(window).off('scroll.popupOpenScroll');
      }
    }
  }

  //for all tags cases
  ocPopupTags = (e) => {
    if (this.props.overflow) return this.ocPopup(e);

    let $dd = this.$dd;

    if(!this.$dd.length) {
      $dd = $(ReactDOM.findDOMNode(this)).children('.dropdown-menu');

      this.$dd = $dd;
    }

    $dd.removeClass('_screenfit').removeClass('_logsmini').removeAttr('style');
    $dd.toggleClass('_open');

    if (this.props.tagsContext === 'log extraction') {
      $dd
        .appendTo(document.body)
        .addClass('_logsmini');

      if($dd.hasClass('_open')) {
        $(window).off('scroll.popupOpenScroll').on('scroll.popupOpenScroll', () => {
          if(!this.ddBtn) return;

          let ddBtnRect = this.ddBtn.getBoundingClientRect(),
              ddBtnBtm = window.innerHeight - ddBtnRect.bottom + ddBtnRect.height / 2,
              ddMenuRect = $dd[0].getBoundingClientRect(),
              $ddArrow = $dd.find('.__arrow'),
              bottom = ddBtnBtm - 22;

          if(ddMenuRect.height < ddBtnBtm) {
            $dd.css({'bottom': ddBtnBtm - 40});
            bottom = 31;
          } else {
            $dd.removeAttr('style');
          }

          $ddArrow.css({'bottom': (
              bottom < 5 ? 5 :
                bottom > ddMenuRect.height - 30 ? ddMenuRect.height - 30 : bottom
            )});

          $dd.css({ right: window.innerWidth - ddBtnRect.left + 20 });

        }).trigger('scroll.popupOpenScroll');
      } else {
        $(window).off('scroll.popupOpenScroll');
      }

      return;
    }

    if ($dd.hasClass('_open')) {
      $(window).off('scroll.popupOpenScroll').on('scroll.popupOpenScroll', () => {
        if(!this.ddBtn) return false;

        let ddBtnRect = this.ddBtn.getBoundingClientRect(),
            ddMenuRect = $dd[0].getBoundingClientRect();

        if(ddMenuRect.height + 30 + ddBtnRect.bottom <= window.innerHeight) {
          $dd.removeClass('_above').removeClass('_aside').css({'margin-top': ''});
        } else if(ddBtnRect.top - 30 - ddMenuRect.height >= 0) {
          $dd.addClass('_above').removeClass('_aside').css({'margin-top': ''});
        } else {
          $dd.removeClass('_above').addClass('_aside');

          let $ddArrow = $dd.find('.__arrow'),
              between = 0;

          if(ddMenuRect.height <= window.innerHeight) {
            between = (window.innerHeight - ddMenuRect.height) / 2;
          } else {
            $dd.css({
              'height': window.innerHeight - 30,
            })

            $dd.addClass('_screenfit');
          }

          let margin = ddBtnRect.bottom - between - ddBtnRect.height / 2;

          $dd.css({'margin-top': -margin});

          $ddArrow.css({'top': (
              margin > ddMenuRect.height - 15 ? ddMenuRect.height - 15 :
                margin < 15 ? 15 : margin
            )});
        }
      }).trigger('scroll.popupOpenScroll');
    } else {
      $(window).off('scroll.popupOpenScroll');
    }
  }

  render() {
    if (!this.props.items || !this.props.items.length) return null;

    let DDTag = (this.props.tag && (typeof this.props.tag) === 'string') ? this.props.tag : `div`;

    let items = this.props.items.reduce((acc, cur, i) => {
        acc.push(
          <li key = {"popupdditem-" + i}>
            {cur}
          </li>
        );

        return acc;
      }, []);

    let body = this.props.body ? this.props.body : null,
        isTagsContext = this.props.tagsContext !== undefined;

    let _props = {
      className: this.props.className,
      'data-tooltip': this.props['data-tooltip'],
      onClick: isTagsContext ? this.ocPopupTags : this.ocPopup,
      ref: (ref) => this.ddBtn = ref
    };

    if (this.props.onHover) delete _props.onClick;

    if(!this.props.overflow) _props.style = {position: "relative"};
    if(this.props.tabIndex !== undefined) _props.tabIndex = this.props.tabIndex;

    let hasFloatArrow = this.props.floatArrow || isTagsContext;

    return (
      <DDTag {..._props}>
        {this.props.children}
        <div className = { classNames("dropdown-menu", {
              "_right": this.props.isRight,
              "_qMark" : this.props.qMark,
              "_nohover": this.props.noHover,
              "_thinli": this.props.isThinli || isTagsContext,
              "_float_arrow": hasFloatArrow
            })
          }
          ref={(ref) => this.dropdown = ref}
          style={ isTagsContext && this.props.overflow ? {zIndex: '1051'} : {}}
        >
          {body}
          {(items !== null && items.length) && <ul>{items}</ul>}
          {hasFloatArrow && <div className='__arrow'/>}
        </div>
      </DDTag>
    );
  }
}
