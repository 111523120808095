import React, { lazy } from 'react';

import { unseteuid } from '../sub-ui/js/settings/common';
import DropdownPopup from './dropdown-popup';
import { pr, onboardingEmit, reopenClick, suspense } from './common';
const SubmitFeedbackDialog = lazy(() => import('./dialog/SubmitFeedbackDlg'));

const submitFeedbackClick = e => {
  pr(e);
  window.modal(suspense(SubmitFeedbackDialog));
}

export default class UserBurger extends React.Component {
  state = {helpEnabled: false}
  helpEnabled = false; //to prevent quick state update

  componentDidMount() {
    let wb = window.buildsite;
    if (wb && wb.onboarding) {
      wb.onboarding.on("newListener", this.checkHelpDisabled);
      wb.onboarding.on("removeListener", this.checkHelpDisabled);
      wb.onboarding.on("hotspot.reposition", this.checkHelpDisabled);
    }
  }

  componentWillUnmount() {
    let wb = window.buildsite;
    if (wb && wb.onboarding) {
      wb.onboarding.removeListener("newListener", this.checkHelpDisabled);
      wb.onboarding.removeListener("removeListener", this.checkHelpDisabled);
      wb.onboarding.removeListener("hotspot.reposition", this.checkHelpDisabled);
    }
  }

  unseteuid = e => {
    unseteuid.call(this, e);
  }

  checkHelpDisabled = () => {
    let wb = window.buildsite;
    if (wb && wb.onboarding) {
      let helpEnabled = (wb.onboarding.listeners("hotspot.on").length > 1);
      if (this.helpEnabled != helpEnabled) {
        this.helpEnabled = helpEnabled;
        this.setState({helpEnabled});
      }
    }
  }

  helpClick = e => pr(e) && this.state.helpEnabled && onboardingEmit("hotspot.on")

  /*
  reinitialize = e => {
    pr(e);
    let u = buildsite.currentUser(),
        prf = u.data.pref,
        nprf = {};
    if (prf) Object.keys(prf).filter(k => (k.indexOf("ph2_onboarding") != 0)).forEach(k => {nprf[k] = prf[k]});
    if (nprf) u.set_key({pref: nprf});
    buildsite.onboarding && buildsite.onboarding.emit("onboarding.on");
    window.modal(WelcomeNew,{});
  }
  */

  //testCanSulogClick = e => {
  //  pr(e);
  //  let u = buildsite.user(),
  //      canslog = u.data && u.data.company && u.data.company.can && u.data.company.can.use_sulog;
  //  u.set_test("company/can/use_sulog", !canslog);
  //  alertify.success("can.use_sulog set to " + (!canslog).toString());
  //  u.apply_test_data();
  //  u.emit("change");
  //}
  //
  //testCanAssignClick = e => {
  //  pr(e);
  //  let u = buildsite.user(),
  //      canassign = u.data && u.data.company && u.data.company.can && u.data.company.can.assign;
  //  u.set_test("company/can/assign", !canassign);
  //  alertify.success("can.assign set to " + (!canassign).toString());
  //  u.apply_test_data();
  //  u.emit("change");
  //}
  //
  //testAdminClick = e => {
  //  pr(e);
  //  let u = buildsite.user(),
  //      isAdmin = u.data && u.data.is_company_admin;
  //  u.set_test("is_company_admin", !isAdmin);
  //  alertify.success("is_company_admin set to " + (!isAdmin).toString());
  //  u.apply_test_data();
  //  u.emit("change");
  //}
  //
  //testEctClick = e => {
  //  pr(e);
  //  let u = buildsite.user(),
  //      ect = u.data && u.data.profile && u.data.profile.email_confirm_time,
  //      nect = ect ? 0 : Math.floor(Date.now()/1000);
  //  u.set_test("profile/email_confirm_time", nect);
  //  alertify.success("profile.email_confirm_time set to " + nect.toString());
  //  u.apply_test_data();
  //  u.emit("change");
  //}

  render() {
    let user = this.props.user || {},
      userName = user.name || user.email_address,
      userProfileMenuItems = (this.props.items || []).slice(0);
    if (!userName) return null;

    if (!this.props.isPublic) {
      if (user.pref && user.pref["ph2_onboarding.checklist_closed"]) {
        userProfileMenuItems.push(
          <a href="#" onClick={reopenClick}>Re-open checklist</a>
        );
      }

      userProfileMenuItems.push(
        <a href="#" className={this.state.helpEnabled ? "" : "disabled"} onClick={this.helpClick}>Show page hints</a>
      );
    }
    //userProfileMenuItems.push(
    //  <a href="#" onClick={this.reinitialize}>Re-initialize Onboarding</a>
    //);
    //userProfileMenuItems.push(
    //  <a href="#" onClick={this.testCanSulogClick}>Toggle can.use_sulog</a>
    //);
    //userProfileMenuItems.push(
    //  <a href="#" onClick={this.testCanAssignClick}>Toggle can.assign</a>
    //);
    //userProfileMenuItems.push(
    //  <a href="#" onClick={this.testAdminClick}>Toggle is_admin</a>
    //);
    //userProfileMenuItems.push(
    //  <a href="#" onClick={this.testEctClick}>Toggle email_confirm_time</a>
    //);

    if (!this.props.no_support) userProfileMenuItems.push(
      <a href="#" onClick={submitFeedbackClick}>Contact Support</a>
    );

    if ((user.status || "") != "ghost") {
      if (user.ruser && !this.props.isPublic) userProfileMenuItems.push(
        <a href="#" onClick={this.unseteuid}>Log out of {userName}&apos;s account</a>
      )
      else userProfileMenuItems.push(
        <a href="/logout">Logout</a>
      );
    } else userProfileMenuItems.push(
        <a href="/logout">Logout</a>
      );

    let props = {
      className: "b-header__user--name _nofull",
      items: userProfileMenuItems,
      down: true
    }

    if(this.props.isPublic === true) {
      props.className = '__dd _user'
    }

    return (
      <DropdownPopup {...props}>
        {this.props.isPublic === true
        ? <span className='__dd-toggl'>{userName}</span>
        : userName
        }
      </DropdownPopup>
    );
  }
}
